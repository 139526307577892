#portfolio {
  padding-top: 90px;
  padding-bottom: 60px;
}
#portfolio h1 {
  font: 15px/24px "opensans-semibold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 48px;
  color: #95a3a3;
}

/* Portfolio Content */
#portfolio-wrapper .columns {
  margin-bottom: 36px;
}
.portfolio-item .item-wrap {
  background: #fff;
  overflow: hidden;
  position: relative;

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portfolio-item .item-wrap a {
  display: block;
  cursor: pointer;
}

/* overlay */
.portfolio-item .item-wrap .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -moz-opacity: 0;
  filter: alpha(opacity=0);
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.Portofolio-Title {
  text-align: center;
  margin-bottom: 50px;
  font-size: 3em;
}
.portfolio-item {
  width: 100% !important;
}
.portfolio-item .item-wrap .link-icon {
  display: flex;
  color: #fff;
  height: 0px;
  width: 30px;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  opacity: 0;
  -moz-opacity: 0;
  filter: alpha(opacity=0);
  transition: all 0.3s ease-in-out;
  background-color: #00bbec;
  width: 100%;
  bottom: 0;
  right: 0;
  position: absolute;
  margin-left: -15px;
  margin-top: -15px;
  justify-content: center;
  flex-direction: column;
}
.portfolio-item .item-wrap img {
  vertical-align: bottom;
  width: 100%;
}
.portfolio-item .portfolio-item-meta {
  padding: 18px;
}
.portfolio-item .portfolio-item-meta h5 {
  font: 14px/21px "opensans-bold", sans-serif;
  color: #fff;
}
.portfolio-item .portfolio-item-meta p {
  font: 12px/18px "opensans-light", sans-serif;
  color: #c6c7c7;
  margin-bottom: 0;
}

/* on hover */
.portfolio-item:hover .overlay {
  opacity: 1;
  -moz-opacity: 1;
  filter: alpha(opacity=100);
}
.portfolio-item:hover .link-icon {
  opacity: 1;
  height: 60px;
  -moz-opacity: 1;
  filter: alpha(opacity=100);
}

/* popup modal */
.popup-modal {
  max-width: 550px;
  background: #fff;
  position: relative;
  margin: 0 auto;
}
.popup-modal .description-box {
  padding: 12px 36px 18px 36px;
}
.popup-modal .description-box h4 {
  font: 15px/24px "opensans-bold", sans-serif;
  margin-bottom: 12px;
  color: #111;
}
.popup-modal .description-box p {
  font: 14px/24px "opensans-regular", sans-serif;
  color: #a1a1a1;
  margin-bottom: 12px;
}
.popup-modal .description-box .categories {
  font: 11px/21px "opensans-light", sans-serif;
  color: #a1a1a1;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: block;
  text-align: left;
}
.popup-modal .description-box .categories i {
  margin-right: 8px;
}
.popup-modal .link-box {
  padding: 18px 36px;
  background: #111;
  text-align: left;
}
.popup-modal .link-box a {
  color: #fff;
  font: 11px/21px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  cursor: pointer;
}
.popup-modal a:hover {
  color: #00cccc;
}
.popup-modal a.popup-modal-dismiss {
  margin-left: 24px;
}

.overlay .magnify {
  background-color: #fff;
  padding: 10px;
  border-radius: 50%;
}
.overlay .magnify2 {
  background-color: #00cccc;
  padding: 10px;
  border-radius: 50%;
  margin-left: 10px;
}
.portofolio-box {
  direction: rtl;
  text-align: center;
  padding: 20px;
  height: 1000px;
}

.portofolio-box img {
  width: 70%;
  height: 50%;
}

.portofolio-box p {
  font-size: 16px;
}

.portofolio-box h1 {
  color: #303030;
  font-size: 60px;
  display: block;
  margin-bottom: 15px;
  padding-bottom: 20px;
  font-weight: 500;
}

.portofolio-box a {
  color: #00bbec;
  border: 2px solid;
  padding: 10px;
  margin: 5px;
  transition: all 0.5s ease;
  float: right;
}

.portofolio-box span {
  padding: 10px;
  direction: rtl;
  text-align: right;
  float: left;
}
