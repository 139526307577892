.white {
  background-color: white !important;
}
#nav-wrap ul,
#nav-wrap li,
#nav-wrap a {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}

/* nav-wrap */
#nav-wrap {
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin: 0 auto;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
}
.opaque {
  background-color: #333;
}

/* hide toggle button */
#nav-wrap > a.mobile-btn {
  display: none;
}

ul#nav {
  min-height: 48px;
  width: auto;
  background: #1c1a1a;
  box-shadow: 0px 4px 8px -2px rgba(9, 30, 66, 0.25), 0px 0px 0px 1px rgba(9, 30, 66, 0.08);
  padding: 10px;
direction: rtl;
  /* center align the menu */
  text-align: center;
}
ul#nav li {
  position: relative;
  list-style: none;
  height: 48px;
  display: inline-block;
}

/* Links */
ul#nav li a {
  /* 8px padding top + 8px padding bottom + 32px line-height = 48px */

  display: inline-block;
  padding: 8px 13px;
  line-height: 32px;
  text-decoration: none;
  text-align: left;
  color: #6b6b6b;
  letter-spacing: 1px;
  font-weight: bold;
  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

ul#nav li a:active {
  background-color: transparent !important;
}
ul#nav li.current a {
  color: #00bbec;
}


.contact-me{
  background: red;
}