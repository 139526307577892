#about {
  background: #ffffff;
  text-align: center;
  overflow: hidden;
  padding: 96px 25%;
}

#about a,
#about a:visited {
  color: #fff;
}
#about a:hover,
#about a:focus {
  color: #00bbec;
}

#about h2 {
  font: 2em "opensans-bold", sans-serif;
  color: #1b1b1b;
  margin-bottom: 12px;
  font-weight: bold;
}

#about hr {
  position: relative;
  border: none;
  height: 5px;
  background: #00bbec;
  opacity: 1;
  width: 20%;
  margin: 0px 10px 30px 10px;
}
#about p {
  line-height: 30px;
  color: #7a7a7a;
}
#about .profile-pic {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 100%;
}
#about .contact-details {
  width: 41.66667%;
  float: right;
}
#about .download {
  width: 58.33333%;
  padding-top: 6px;
}
#about .download .button {
  margin-top: 6px;
  background: #00bbec;
  border-radius: 10px;
  color: #fff;
  position: absolute ;
  right: 0;
}

#about .download .button:hover {
  background: #fff;
  color: #2b2b2b;
}
#about .download .button i {
  margin-right: 15px;
  font-size: 20px;
}

.hrparent {
  text-align: center;
  display: flex;
  justify-content: center;
}
