/* ------------------------------------------------------------------ */
/* h. Contact Section
/* ------------------------------------------------------------------ */

#contact {
  background: #191919;
  padding-top: 96px;
  padding-bottom: 102px;
  color: #636363;
}
#contact .section-head {
  margin-bottom: 42px;
}

#contact a,
#contact a:visited {
  color: #11abb0;
}
#contact a:hover,
#contact a:focus {
  color: #00bbec;
}

#contact h1 {
  font: 18px/24px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #ebeeee;
  margin-bottom: 6px;
}
#contact h1 span {
  display: none;
}
#contact h1:before {
  font-family: "FontAwesome";
  content: "\f0e0";
  padding-right: 10px;
  font-size: 72px;
  line-height: 72px;
  text-align: left;
  float: left;
  color: #ebeeee;
}

#contact h4 {
  font: 16px/24px "opensans-bold", sans-serif;
  color: #ebeeee;
  margin-bottom: 6px;
}
#contact p.lead {
  font: 18px/36px "opensans-light", sans-serif;
  padding-right: 3%;
}
#contact .header-col {
  padding-top: 6px;
}

/* contact form */
#contact form {
  margin-bottom: 30px;
}
#contact label {
  font: 15px/24px "opensans-bold", sans-serif;
  margin: 12px 0;
  color: #ebeeee;
  display: inline-block;
  float: right;
  width: 26%;
}
#contact input,
#contact textarea,
#contact select {
  padding: 10px;
  margin: 0 !important;
  color: #eee;
  background: #ffffff;
  margin-bottom: 42px;
  border: 0;
  outline: none;
  font-size: 15px;
  line-height: 24px;
  width: 100%;
  border-radius: 10px;
}
#contact input:focus,
#contact textarea:focus,
#contact select:focus {
  color: #fff;
  background-color: #11abb0;
}
#contact button.submit {
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
  background: #0d0d0d;
  border: none;
  cursor: pointer;
  height: auto;
  display: inline-block;
  border-radius: 3px;
  margin-left: 26%;
}
#contact button.submit:hover {
  color: #0d0d0d;
  background: #00bbec;
}
#contact span.required {
  color: #11abb0;
  font-size: 13px;
}
#message-warning,
#message-success {
  display: none;
  background: #0f0f0f;
  padding: 24px 24px;
  margin-bottom: 36px;
  width: 65%;
  margin-left: 26%;
}
#message-warning {
  color: #d72828;
}
#message-success {
  color: #11abb0;
}

#message-warning i,
#message-success i {
  margin-right: 10px;
}
#image-loader {
  display: none;
  position: relative;
  left: 18px;
  top: 17px;
}

/* Twitter Feed */
#twitter {
  margin-top: 12px;
  padding: 0;
}
#twitter li {
  margin: 6px 0px 12px 0;
  line-height: 30px;
}
#twitter li span {
  display: block;
}
#twitter li b a {
  font: 13px/36px "opensans-regular", Sans-serif;
  color: #474747 !important;
  border: none;
}

/* ------------------------------------------------------------------ */
/* i. Footer
 /* ------------------------------------------------------------------ */

footer {
  color: #303030;
  font-size: 14px;
  text-align: center;
  position: relative;
}

footer a,
footer a:visited {
  color: #525252;
}
footer a:hover,
footer a:focus {
  color: #00bbec;
}

/* copyright */
footer .copyright {
  margin: 0;
  padding: 0px;
  background: #121212;
  color: #9b9b9b;
}
footer .copyright li {
  display: inline-block;
  margin: 0;
  padding: 0;
  line-height: 24px;
}
.ie footer .copyright li {
  display: inline;
}
footer .copyright li:before {
  content: "\2022";
  padding-left: 10px;
  padding-right: 10px;
  color: #095153;
}
footer .copyright li:first-child:before {
  display: none;
}

/* social links */
footer .social-links {
  margin: 18px 0 30px 0;
  padding: 0;
  font-size: 30px;
}
footer .social-links li {
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-left: 42px;
  color: #f06000;
}

footer .social-links li:first-child {
  margin-left: 0;
}

#go-top {
  position: fixed;
  display: flex;
  justify-content: right;

  left: 0px;
  bottom: 20px;
}
#go-top a {
  text-decoration: none;
  border: 0 none;
  display: block;
  width: 60px;
  height: 60px;
  background-color: #00bbec;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  color: #fff;
  font-size: 21px;
  line-height: 60px;
  border-radius: 100%;
}
#go-top a:hover {
  background-color: #00bbec;
}
.copyright {
  width: 100% !important;
  position: absolute !important;
  left: 0 !important;
}

.copyright-title {
  display: flex;
  justify-content: space-between;
  padding: 20px 20%;
}

.social-side a {
  fill: #bababa;
  width: 100px;
  margin-left: 20px;
}

#contact hr {
  position: relative;
  border: none;
  height: 5px;
  background: #00bbec;
  opacity: 1;
  width: 50%;
  margin: 0px 0px 30px 10px;
}

#contact h2 {
  color: #fff;
}
#contact svg {
  fill: #a1a1a1;
  padding-left: 10px;
}
#contact p {
  margin-bottom: 20px;
}
#contact a {
  cursor: pointer;
}
