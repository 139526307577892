body {
  background: #0f0f0f;
}

header {
  position: relative;
  min-height: 200px;
  width: 100%;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  text-align: center;
  overflow: hidden;
}

header:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
header .banner {
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  width: 85%;
  padding-bottom: 30px;
  text-align: center;
}

header .banner-text {
  width: 100%;
}
header .banner-text h1 {
  font: 90px/1.1em "opensans-bold", sans-serif;
  color: #fff;
  letter-spacing: -2px;
  margin: 0 auto 18px auto;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
}
header .banner-text h3 {
  font: 18px/1.9em "librebaskerville-regular", serif;
  color: #a8a8a8;
  margin: 0 auto;
  width: 70%;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}
header .banner-text h3 span,
header .banner-text h3 a {
  color: #fff;
}
header .banner-text hr {
  width: 60%;
  margin: 18px auto 24px auto;
  border-color: #2f2d2e;
  border-color: rgba(150, 150, 150, 0.1);
}

/* header social links */
header .social {
  margin: 24px 0;
  padding: 0;
  font-size: 30px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
}
header .social li {
  display: inline-block;
  margin: 0 15px;
  padding: 0;
}
header .social li a {
  color: #fff;
}
header .social li a:hover {
  color: #00bbec;
}

.scrolldown {
  position: absolute;
  top: 900px !important;
  bottom: 0px;
  right: 0px;
  height: 0;
  left: 0px;
  z-index: 111;
}
/* scrolldown link */
header .scrolldown a {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -29px;
  color: #fff;
  display: block;
  height: 42px;
  width: 42px;
  font-size: 42px;
  line-height: 42px;
  color: #fff;
  border-radius: 100%;

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header .scrolldown a:hover {
  color: #00bbec;
}

ul#nav {
  display: flex;
  justify-content: center;
}

.smoothscroll {
  top: 0;
  height: 10px;
}

.mouse-anim {
  position: absolute;
  top: 250px;
  z-index: 1 !important;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(100px);
  }
}

svg #wheel {
  animation: scroll ease 1.5s infinite;
}
