#resume {
  background: #fff;
  padding-top: 90px;
  padding-bottom: 72px;
  overflow: hidden;
  text-align: right;
}

#resume a,
#resume a:visited {
  color: #00bbec;
}
#resume a:hover,
#resume a:focus {
  color: #313131;
}

#resume h1 {
  font: 18px/24px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}
#resume h1 span {
  border-bottom: 3px solid #00bbec;
  padding-bottom: 6px;
  margin-top: 30px;
}
#resume h3 {
  font: 25px/30px "opensans-bold", sans-serif;
}

#resume .header-col {
  padding-top: 9px;
  margin-top: 30px;
}
#resume .main-col {
  margin-top: 30px;
  width: 100%;
}

.education,
.work {
  margin-bottom: 48px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e8e8e8;
}
#resume .info {
  font: 16px/24px "librebaskerville-italic", serif;
  color: #6e7881;
  margin-bottom: 18px;
  margin-top: 9px;
}
#resume .info span {
  margin-right: 5px;
  margin-left: 5px;
}
#resume .date {
  font: 15px/24px "opensans-regular", sans-serif;
  margin-top: 6px;
}

/*----------------------------------------------*/
/*	Skill Bars
 /*----------------------------------------------*/

.bars {
  width: 95%;
  float: right;
  padding: 0;
  text-align: left;
}
.bars .skills {
  margin-top: 36px;
  list-style: none;
  text-align: right;
}
.bars li {
  position: relative;
  margin-bottom: 60px;
  background: #ccc;
  height: 42px;
  border-radius: 50px;
}
.bars li em {
  font: 15px "opensans-bold", sans-serif;
  color: #313131;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: normal;
  position: relative;
  top: -36px;
  font-family: IRANSans !important;
}
.bar-expand {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding-right: 24px;
  background: #00bbec;
  display: inline-block;
  height: 42px;
  line-height: 42px;
  border-radius: 50px;
}

.photoshop {
  width: 60%;
  -moz-animation: photoshop 2s ease;
  -webkit-animation: photoshop 2s ease;
}
.illustrator {
  width: 55%;
  -moz-animation: illustrator 2s ease;
  -webkit-animation: illustrator 2s ease;
}
.wordpress {
  width: 50%;
  -moz-animation: wordpress 2s ease;
  -webkit-animation: wordpress 2s ease;
}
.css {
  width: 90%;
  -moz-animation: css 2s ease;
  -webkit-animation: css 2s ease;
}
.html5 {
  width: 80%;
  -moz-animation: html5 2s ease;
  -webkit-animation: html5 2s ease;
}
.jquery {
  width: 50%;
  -moz-animation: jquery 2s ease;
  -webkit-animation: jquery 2s ease;
}

@-moz-keyframes photoshop {
  0% {
    width: 0px;
  }
  100% {
    width: 60%;
  }
}
@-moz-keyframes illustrator {
  0% {
    width: 0px;
  }
  100% {
    width: 55%;
  }
}
@-moz-keyframes wordpress {
  0% {
    width: 0px;
  }
  100% {
    width: 50%;
  }
}
@-moz-keyframes css {
  0% {
    width: 0px;
  }
  100% {
    width: 90%;
  }
}
@-moz-keyframes html5 {
  0% {
    width: 0px;
  }
  100% {
    width: 80%;
  }
}
@-moz-keyframes jquery {
  0% {
    width: 0px;
  }
  100% {
    width: 50%;
  }
}

@-webkit-keyframes photoshop {
  0% {
    width: 0px;
  }
  100% {
    width: 60%;
  }
}
@-webkit-keyframes illustrator {
  0% {
    width: 0px;
  }
  100% {
    width: 55%;
  }
}
@-webkit-keyframes wordpress {
  0% {
    width: 0px;
  }
  100% {
    width: 50%;
  }
}
@-webkit-keyframes css {
  0% {
    width: 0px;
  }
  100% {
    width: 90%;
  }
}
@-webkit-keyframes html5 {
  0% {
    width: 0px;
  }
  100% {
    width: 80%;
  }
}
@-webkit-keyframes jquery {
  0% {
    width: 0px;
  }
  100% {
    width: 50%;
  }
}

.portfolio-item {
  width: 100%;
}

#resume hr {
  position: relative;
  border: none;
  height: 5px;
  background: #00bbec;
  opacity: 1;
  width: 20%;
  margin: 0px 0px 30px 10px;
  float: right;
}
